<template>
  <div>
    <b-card title="Jobs">
      <blueprint-data
        :api-route-path="`bookings/${bookingId}/jobs`"
        :fields="fields"
        no-new
        no-actions
        no-search
      >
        <template v-slot:status="{ item }">
          <b-badge v-if="item.status === 'ERROR'" variant="danger">
            Error
          </b-badge>
          <b-badge v-if="item.status === 'CANCELED'" variant="warning">
            Canceled
          </b-badge>
          <b-badge v-if="item.status === 'SCHEDULED'" variant="warning">
            Scheduled
          </b-badge>
          <b-badge v-if="item.status === 'RESCHEDULED'" variant="warning">
            Rescheduled
          </b-badge>
          <b-badge v-if="item.status === 'OK'" variant="success">
            Done
          </b-badge>
          <small v-if="item.autoResceduleCount > 0">
            Auto rescheduled count: <b-badge variant="warning">{{ item.autoResceduleCount }}</b-badge>
          </small>
        </template>
        <template v-slot:output="{ item }">
          <b-badge v-if="item.output && item.output.error && item.output.message" variant="danger">
            {{ item.output.message }}
          </b-badge>
          <small v-else><code>{{ item.output }}</code></small>
        </template>
        <template v-slot:attrs="{ item }">
          <span v-if="item.attrs.deviceId">
            Device:
            <strong><ObjectName :id="item.attrs.deviceId" object="inventory/devices" /></strong><br>
            <small>{{ item.attrs.deviceId }}</small>
            <br><br>
          </span>
          <span v-if="item.attrs.unitId">
            Unit:
            <strong><ObjectName :id="item.attrs.unitId" object="inventory/units" /></strong><br>
            <small>{{ item.attrs.unitId }}</small>
            <br><br>
          </span>
        </template>
        <template v-slot:duration="{ item }">
          <span v-if="item.duration">{{ item.duration }}s</span>
        </template>
        <template v-slot:name="{ item }">
          <b-badge variant="dark">
            {{ item.name }}
          </b-badge>
        </template>
        <template v-slot:scheduled="{ item }">
          <span>{{ item.scheduled | moment('lll') }}</span>
        </template>
        <template v-slot:createdAt="{ item }">
          <span>{{ item.createdAt | moment('lll') }}</span>
        </template>
      </blueprint-data>
    </b-card>
    <br>
  </div>
</template>

<script>
import ObjectName from './object-name.vue';

export default {
  components: {
    ObjectName
  },
  props: ['bookingId'],
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true,
          slot: true,
        },
        /*
        {
          key: 'attrs',
          sortable: false,
          slot: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          sortable: true,
          slot: true,
        },
        */
        {
          key: 'scheduled',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },/*
        {
          key: 'output',
          sortable: false,
          slot: true,
        },*/
        {
          key: 'duration',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
};
</script>
